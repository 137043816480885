import React from 'react';

import Layout from '../components/layout';
import Container from '../components/container';

const PrivacyPolicy = () => (
  <Layout title='Privacy policy - Supersheets'>
    <Container>
      <div>
        <div className='max-w-2xl m-auto mb-16'>
          <h1 className='font-serif text-center font-light text-3xl md:font-medium p-4 md:p-8'>
            Supersheets, Inc Privacy Policy
          </h1>
          <Section>Introduction</Section>
          <List>
            <li> Introduction </li>
            <li> Data Protection Officer </li>
            <li>How we collect and use (process) your personal information </li>
            <li> Use of the [Cotera.co](http://cotera.co/) website </li>
            <li> Cookies and tracking technologies </li>
            <li> Use of the Cotera services </li>
            <li> When and how we share information with third parties </li>
            <li> Transferring personal data to the U.S. </li>
            <li> Data Subject rights </li>
            <li> Security of your information </li>
            <li> Data storage and retention </li>
            <li> Questions, concerns, or complaints </li>
          </List>
          Supersheets, Inc. (Supersheets) is a software company company
          incorporated in Delaware. Cotera is a trading name of Supersheets.
          <br />
          We understand that you are aware of and care about your own personal
          privacy interests, and we take that seriously. This Privacy Notice
          describes Supersheets’s policies and practices regarding its
          collection and use of your personal data, and sets forth your privacy
          rights. We recognize that information privacy is an ongoing
          responsibility, and so we will from time to time update this Privacy
          Notice as we undertake new personal data practices or adopt new
          privacy policies.
          <br />
          <br />
          <Section>Data Protection Officer</Section>
          Supersheets is headquartered in New York, NY, in the United States.
          Supersheets has appointed an internal data protection officer for you
          to contact if you have any questions or concerns about Supersheets’s
          personal data policies or practices. If you would like to exercise
          your privacy rights, please direct your query to Supersheets’s data
          protection officer. Supersheets’s data protection officer’s name and
          contact information are as follows:
          <br />
          <br />
          Ibrahim Syed
          <br />
          Supersheets
          <br />
          Supersheets, Inc.
          <br />
          228 Park Ave S
          <br />
          PMB 39887
          <br />
          New York, New York 10003-1502 US
          <br />
          founders@cotera.co
          <br />
          646-641-1650
          <Section>
            How we collect and use (process) your personal information
          </Section>
          Supersheets collects personal information about its website visitors
          and customers. With a few exceptions, this information is generally
          limited to:
          <List>
            <li> name </li>
            <li> job title </li>
            <li> employer name </li>
            <li> work address </li>
            <li> work email </li>
            <li> work phone number </li>
          </List>
          We use this information to provide prospects and customers with
          services. We do not sell personal information to anyone and only share
          it with third parties who are facilitating the delivery of our
          services. From time to time, Supersheets receives personal information
          about individuals from third parties. Typically, information collected
          from third parties will include further details on your employer or
          industry. We may also collect your personal data from a third party
          website (e.g. LinkedIn)
          <Section>
            Use of the <Link href='https://cotera.co'>cotera.co</Link> website{' '}
          </Section>
          As is true of most other websites, Cotera’s website collects certain
          information automatically and stores it in log files. The information
          may include internet protocol (IP) addresses, the region or general
          location where your computer or device is accessing the internet,
          browser type, operating system and other usage information about the
          use of Cotera’s website, including a history of the pages you view. We
          use this information to help us design our site to better suit our
          users’ needs. We may also use your IP address to help diagnose
          problems with our server and to administer our website, analyze
          trends, track visitor movements, and gather broad demographic
          information that assists us in identifying visitor preferences.
          <br />
          <br />
          Supersheets has a legitimate interest in understanding how members,
          customers and potential customers use its website. This assists
          Supersheets with providing more relevant products and services, with
          communicating value to our sponsors and corporate members, and with
          providing appropriate staffing to meet member and customer needs.
          <Section> Cookies and tracking technologies </Section>
          Supersheets makes available a comprehensive list of the tracking
          technologies used on our website. At present, the following
          technologies are used:
          <List>
            <li>Google Analytics</li>
            <li>Clearbit</li>
          </List>
          <Section> Use of the Cotera services </Section>
          <Section>
            When and how we share information with third parties
          </Section>
          The personal information Supersheets collects from you is stored in
          one or more databases hosted by third parties located in the United
          States. These third parties do not use or have access to your personal
          information for any purpose other than cloud storage and retrieval. On
          occasion, Supersheets engages third parties to send information to
          you, including information about our products, services, and events.
          <br />
          <br />
          Here is a list of our third party subprocessors:
          <List>
            <li>google.com</li>
            <li>mailchimp.com</li>
            <li>hubspot.com</li>
          </List>
          We do not otherwise reveal your personal data to non-Supersheets
          persons or businesses for their independent use unless: (1) you
          request or authorize it; (2) it’s in connection with
          Supersheets-hosted and Supersheets co-sponsored conferences as
          described above; (3) the information is provided to comply with the
          law (for example, compelled by law enforcement to comply with a search
          warrant, subpoena, or court order), enforce an agreement we have with
          you, or to protect our rights, property or safety, or the rights,
          property or safety of our employees or others; (4) the information is
          provided to our agents, vendors or service providers who perform
          functions on our behalf; (5) to address emergencies or acts of God; or
          (6) to address disputes, claims, or to persons demonstrating legal
          authority to act on your behalf. We may also gather aggregated data
          about our services and website visitors and disclose the results of
          such aggregated (but not personally identifiable) information to our
          partners, service providers, advertisers, and/or other third parties
          for marketing or promotional purposes.
          <br />
          <br />
          The Cotera website connects with third party services such as Clearbit
          and others. If you choose to share information from the Cotera website
          through these services, you should review the privacy policy of that
          service. If you are a member of a third party service, the
          aforementioned connections may allow that service to connect your
          visit to our site to your personal data.
          <Section> Transferring personal data to the U.S. </Section>
          Supersheets has its headquarters in the United States. Information we
          collect about you will be processed in the United States. By using
          Supersheets’s services, you acknowledge that your personal information
          will be processed in the United States. The United States has not
          sought nor received a finding of “adequacy” from the European Union
          under Article 45 of the GDPR. Pursuant to Article 46 of the GDPR,
          Supersheets is providing for appropriate safeguards by entering
          binding, standard data protection clauses, enforceable by data
          subjects in the EEA and the UK. These clauses have been enhanced based
          on the guidance of the European Data Protection Board and will be
          updated when the new draft model clauses are approved.
          <br />
          <br />
          Depending on the circumstance, Supersheets also collects and transfers
          to the U.S. personal data with consent; to perform a contract with
          you; or to fulfill a compelling legitimate interest of Supersheets in
          a manner that does not outweigh your rights and freedoms. Supersheets
          endeavors to apply suitable safeguards to protect the privacy and
          security of your personal data and to use it only consistent with your
          relationship with Supersheets and the practices described in this
          Privacy Statement. Supersheets also enters into data processing
          agreements and model clauses with its vendors whenever feasible and
          appropriate. Since it was founded, Supersheets has received zero
          government requests for information.
          <br />
          <br />
          For more information or if you have any questions, please contact us
          at founders@cotera.co
          <Section> Data Subject rights </Section>
          The European Union’s General Data Protection Regulation (GDPR) and
          other countries’ privacy laws provide certain rights for data
          subjects. Data Subject rights under GDPR include the following:
          <List>
            <li> Right to be informed </li>
            <li> Right of access </li>
            <li> Right to rectification </li>
            <li> Right to erasure </li>
            <li> Right to restrict processing </li>
            <li> Right of data portability </li>
            <li> Right to object </li>
            <li>
              {' '}
              Rights related to automated decision making including profiling{' '}
            </li>
          </List>
          This Privacy Notice is intended to provide you with information about
          what personal data Supersheets collects about you and how it is used.
          If you wish to confirm that Supersheets is processing your personal
          data, or to have access to the personal data Supersheets may have
          about you, please contact us.
          <br />
          <br />
          You may also request information about: the purpose of the processing;
          the categories of personal data concerned; who else outside
          Supersheets might have received the data from Supersheets; what the
          source of the information was (if you didn’t provide it directly to
          Supersheets); and how long it will be stored. You have a right to
          correct (rectify) the record of your personal data maintained by
          Supersheets if it is inaccurate. You may request that Supersheets
          erase that data or cease processing it, subject to certain exceptions.
          You may also request that Supersheets cease using your data for direct
          marketing purposes. In many countries, you have a right to lodge a
          complaint with the appropriate data protection authority if you have
          concerns about how Supersheets processes your personal data. When
          technically feasible, Supersheets will—at your request—provide your
          personal data to you.
          <br />
          <br />
          Reasonable access to your personal data will be provided at no cost.
          If access cannot be provided within a reasonable time frame,
          Supersheets will provide you with a date when the information will be
          provided. If for some reason access is denied, Supersheets will
          provide an explanation as to why access has been denied.
          <br />
          <br />
          For questions or complaints concerning the processing of your personal
          data, you can email us at founders@cotera.co. Alternatively, if you
          are located in the European Union, you can also have recourse to the
          European Data Protection Supervisor or with your nation’s data
          protection authority.
          <Section> Security of your information </Section>
          Your personal data is stored by the Supersheets on its servers, and on
          the servers of the cloud-based database management services the
          Supersheets engages, located in the United States. The Supersheets
          retains service data for the duration of the customer’s business
          relationship with the Supersheets and for a period of time thereafter,
          to analyze the data for Supersheets’s own operations, and for
          historical and archiving purposes associated with Supersheets’s
          services. Supersheets retains prospect data until such time as it no
          longer has business value and is purged from Supersheets systems. All
          personal data that Supersheets controls may be deleted upon verified
          request from Data Subjects or their authorized agents. For more
          information on where and how long your personal data is stored, and
          for more information on your rights of erasure and portability, please
          contact us at: founders@cotera.co
          <Section> Data storage and retention </Section>
          We do not knowingly attempt to solicit or receive information from
          children.
          <Section> Questions, concerns, or complaints </Section>
          Ibrahim Syed
          <br />
          Supersheets
          <br />
          Supersheets, Inc.
          <br />
          228 Park Ave S
          <br />
          PMB 39887
          <br />
          New York, New York 10003-1502 US
        </div>
      </div>
    </Container>
  </Layout>
);

export default PrivacyPolicy;

const Section = (props) => (
  <h3 className='font-serif font-bold py-2 md:py-4'>{props.children}</h3>
);

const List = (props) => (
  <ul className='list-disc list-inside py-2 md:py-4'>{props.children}</ul>
);

const Link = (props) => (
  <a {...props} className='text-md text-indigo-600'>
    {props.children}
  </a>
);
